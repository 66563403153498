.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  color: white;
}

.logoContainer .logo {
  height: 50px;
}

.navLinks {
  display: flex;
  list-style: none;
  gap: 5px;
  padding: 0;
  margin: 0;
}

.navLinks li {
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  border-bottom: 2px solid #000;
  transition: border-bottom 0.5s ease, transform 0.5s ease;
  display: flex;
  /* align-items: center; */
}

.navLinks li:hover {
  border-bottom: 2px solid #f0a500;
  transform: translateY(-2px);
}

.navLinks .active {
  color: white;
  font-weight: bold;
  border-bottom: 2px solid #f0a500;
  padding-bottom: 3px;
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cartIcon {
  font-size: 24px;
  cursor: pointer;
  transition: color 0.5s ease, transform 0.5s ease;
}

.cartIcon:hover {
  color: var(--secondary);
  transform: scale(1.1);
}

.loginBtn,
.signupBtn {
  width: 100px;
  background-color: transparent;
  color: white;
  padding: 10px 0;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 14px;
  border-radius: 20px;
  transition: background-color 0.5s ease, color 0.5s ease, transform 0.5s ease;
}

.signupBtn {
  background-color: white;
  color: black;
}

.loginBtn:hover {
  background-color: var(--secondary);
  color: black;
  transform: scale(1.05);
}

.signupBtn:hover {
  background-color: var(--secondary);
  color: black;
  transform: scale(1.05);
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 18px;
  margin-left: 20px;
}

.userIcon {
  font-size: 30px;
}

.dropdownIcon {
  margin-left: 5px;
}

.shopDropdown {
  position: absolute;
  top: 100%;
  left: -20px;
  width: 140px;
  height: 200px;
  overflow-y: auto;
  background-color: white;
  list-style: none;
  margin: 0;
  margin-top: 5px;
  border-radius: 10px;
  z-index: 1000;
}

.shopDropdown li {
  border-radius: 0;
  border-bottom: 2px solid white;
  border-bottom: none;
  color: black;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.shopDropdown li:hover {
  border-bottom: 2px solid white;
  background-color: var(--secondary);
}
