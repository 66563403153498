.contactContainer {
  color: white;
}

.contactContainer h1 {
  color: var(--secondary);
  text-align: center;
  margin-bottom: 20px;
}

.contactContent {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contactForm {
  width: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.formGroup label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 1rem;
}

.formIcon {
  color: white;
  font-size: 20px;
}

.contactForm input,
.contactForm textarea {
  width: 100%;
  padding: 10px;
  background-color: #1e1e1e;
  border: 1px solid #333;
  border-radius: 5px;
  color: white;
}

.contactForm input::placeholder,
.contactForm textarea::placeholder {
  color: rgb(199, 199, 199);
}

.contactForm textarea {
  min-height: 150px;
  resize: vertical;
}

.contactForm button {
  background-color: var(--secondary);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
}

.contactForm button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Contact Details Styles */
.contactDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.contactItem,
.whatsappButton {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.contactItem:hover,
.whatsappButton:hover {
  color: white;
  background-color: var(--secondary);
}

.contactIcon,
.whatsappIcon {
  font-size: 20px;
}
