.productMainContainer {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  margin-top: 20px;
}

.productMainSidebar {
  min-width: 20%;
  max-height: 100vh;
  position: sticky;
  top: 0;
  padding: 10px;
  padding-top: 0;
}
.filterHeader {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: var(--secondary);
  font-size: 22px;
  padding: 7px;
  margin-bottom: 10px;
  z-index: 1;
}
.filter-section {
  margin-bottom: 14px;
}

.filter-button {
  width: 100%;
  padding: 10px;
  text-align: left;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  transition: background-color 0.3s ease-in-out, border 3s ease-in-out,
    color 0.3s ease-in-out;
}

.filter-button:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: black;
}

.filter-list {
  height: 250px;
  list-style: none;
  padding: 0;
  margin: 0px 0 0 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
}

.filter-list li {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.filter-list li:last-child {
  border-bottom: none;
}

.filter-list li:hover {
  background-color: #f1f1f1;
}

.productheadingContainer > h3 {
  color: white;
  padding-left: 20px;
  font-size: 24px;
}

.productheadingContainer > h3 > span {
  font-size: 16px;
  color: var(--secondary);
}

.sortDivMain {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  z-index: 999;
  position: relative;
}

.sort-section {
  width: 16%;
  margin-bottom: 14px;
}

.sort-button {
  width: 100%;
  padding: 10px;
  text-align: left;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  transition: background-color 0.3s ease-in-out, border 3s ease-in-out,
    color 0.3s ease-in-out;
}

.sort-button:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: black;
}

.sort-list {
  list-style: none;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.sort-list li {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.sort-list li:last-child {
  border-bottom: none;
}

.sort-list li:hover {
  background-color: #f1f1f1;
}

.productMainContent {
  max-width: 80%;
}
