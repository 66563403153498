#footerContainer {
  width: 100%;
  padding-bottom: 10px;
  background-color: #000;
  margin-top: 30px;
}
#footerContainer > .footerTopInfo {
  padding-top: 15px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#footerContainer > .footerTopInfo > div {
  width: 30%;
  height: 100%;
  line-height: 30px;
  text-align: center;
}
#footerContainer > .footerTopInfo > div > .innerFooterDiv {
  width: fit-content;
  margin: auto;
}
#footerContainer > .footerTopInfo > div > .innerFooterDiv > h4 {
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--secondary);
  text-align: left;
}
#footerContainer > .footerTopInfo > div > .innerFooterDiv > ul {
  list-style: none;
}
#footerContainer > .footerTopInfo > div > .innerFooterDiv > ul > li {
  cursor: pointer;
  color: #ffffff;
  text-align: left;
}
#footerContainer > .footerTopInfo > div > .innerFooterDiv > ul > li:hover {
  color: var(--secondary);
}
#footerContainer > .footerTopInfo > div:last-child > ul {
  display: flex;
  flex-direction: column;
}
#footerContainer > .footerTopInfo > div:last-child > .innerFooterDiv > ul > li {
  width: 100px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
#footerDivider {
  width: 100%;
  height: 1px;
  background-color: rgb(78, 78, 78);
  margin-top: 20px;
  margin-bottom: 15px;
}

#footerContainer > .footerBottomInfo {
  background-color: var(--secondary);
  padding: 7px;
  margin-top: 20px;
}
#footerContainer > .footerBottomInfo > p {
  text-align: center;
  font-size: 16px;
  color: #000000;
}
