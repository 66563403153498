.product-container {
  color: white;
  padding: 20px 60px;
}

.product-container-split {
  display: flex;
  gap: 100px;
  color: white;
}
.image-gallery {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.thumbnail-images {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.thumbnail-images img {
  width: 80px;
  height: 100px;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}

.thumbnail-images img:hover {
  transform: scale(1.1);
}

.thumbnail-images img.active {
  border: 2px solid var(--secondary);
}

.main-image {
  width: 500px;
  border-radius: 10px;
  object-fit: cover;
}

.product-details {
  flex: 1;
}

.product-title {
  font-size: 30px;
  font-weight: lighter;
  color: var(--secondary);
  margin-bottom: 14px;
}

.product-vendor {
  font-size: 16px;
  opacity: 0.8;
  margin-bottom: 10px;
}

.product-price {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.product-price del {
  color: gray;
  margin-left: 10px;
}

.discount {
  color: var(--secondary);
  font-weight: bold;
}

.size-options,
.color-options {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.size-options button {
  background: var(--secondary);
  color: black;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 16px;
}

.color-options span {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 20px;
}

.product-description {
  margin-top: 15px;
}

.product-description {
  list-style-type: disc;
  padding-left: 20px;
}

.product-description li {
  margin-bottom: 10px;
}

.button-group {
  margin-top: 40px;
  display: flex;
  gap: 15px;
}

.button-group button {
  padding: 10px;
  width: 180px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-group button > svg {
  font-size: 24px;
  margin-right: 10px;
}

.add-to-cart {
  background: var(--secondary);
  color: black;
  border: 1px solid var(--secondary);
}

.add-to-cart:hover {
  color: var(--secondary);
  background-color: transparent;
}

.buy-now {
  background-color: white;
  color: black;
  border: 1px solid white;
}

.buy-now:hover {
  border: none;
  background-color: var(--secondary);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
    align-items: center;
  }

  .image-gallery {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .thumbnail-images {
    flex-direction: row;
  }

  .thumbnail-images img {
    width: 60px;
    height: 80px;
  }

  .main-image {
    width: 280px;
    height: 350px;
  }

  .product-details {
    text-align: center;
  }

  .button-group {
    justify-content: center;
  }
}
