.newArrivalProductContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  padding: 0 20px;
  gap: 14px;
}

.newArrivalTagLine {
  color: var(--secondary);
  text-align: center;
  margin-top: 50px;
  font-size: 28px;
  margin-bottom: 20px;
}

.newArrivalProductContainer > .newArrivalProduct {
  width: 24%;
  height: 420px;
  border: 1px solid #b4b4b4;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, border 0.3s ease-in-out;
}

.newArrivalProductContainer > .newArrivalProduct:hover {
  transform: scale(1.03);
  border: 1px solid var(--secondary);
}

.newArrivalProductContainer > .newArrivalProduct > .newArrivalProductTop {
  width: 100%;
  height: 80%;
}
.newArrivalProductContainer > .newArrivalProduct > .newArrivalProductTop > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.newArrivalProductContainer > .newArrivalProduct > .newArrivalProductBottom {
  width: 100%;
  height: 20%;
  padding: 4px 8px;
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > p {
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .category_ {
  color: rgb(214, 214, 214);
  font-size: 14px;
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .price_
  > b {
  font-size: 18px;
  font-weight: bold;
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .price_
  > s {
  color: rgb(214, 214, 214);
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .price_
  > span {
  color: rgb(137, 187, 0);
  color: var(--secondary);
}
