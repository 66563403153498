.orders-container {
  color: #fff;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.orders-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: var(--secondary);
  grid-column: 1 / -1;
}

/* Order Card */
.order-card {
  display: flex;
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.order-image img {
  width: 150px;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.order-details {
  flex: 1;
}

.order-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: var(--secondary);
}

.order-price {
  font-size: 1.5rem;
  color: var(--secondary);
  margin-bottom: 15px;
}

.order-info {
  font-size: 1rem;
  line-height: 1.6;
}

.info-label {
  font-weight: bold;
  color: var(--secondary);
}

.status {
  padding: 4px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.status.paid {
  background-color: #28a745;
  color: #fff;
}

.status.pending {
  background-color: #ffc107;
  color: #000;
}

.status.shipped {
  background-color: #17a2b8;
  color: #fff;
}

.status.processing {
  background-color: #6c757d;
  color: #fff;
}
.deliveryStatus {
  margin: 10px 0;
}

/* Star Rating Styles */

.flexItems {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.stars {
  display: flex;
  gap: 0.2rem;
  justify-content: center;
}

.star {
  font-size: 1.2rem;
  color: #e6e6e6;
  cursor: pointer;
  transition: color 0.3s ease;
}

.star.active {
  color: var(--secondary);
}

.rating-text {
  font-size: 0.875rem;
  color: #ccc;
  margin-top: 0.5rem;
}

.rating-label {
  font-size: 1rem;
  color: #ccc;
  text-align: center;
}
