.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1000;
}

.cart-modal {
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 700px;
  background-color: white;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 1001;
  padding: 20px;
  animation: slideUp 0.5s ease-in-out;
}
.cart-modal {
  animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.cart-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.cart-content {
  max-height: 300px;
  overflow-y: auto;
  border-bottom: 1px solid rgb(228, 228, 228);
  padding-right: 20px;
}

.cart-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.item-image {
  width: 80px;
  height: 100px;
  margin-right: 15px;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

.item-details {
  flex: 1;
}

.item-details > .removeCartBtn {
  color: #ff0000;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.item-details > .removeCartBtn:hover {
  color: #c90000;
}

.item-details h3 {
  margin: 0;
  font-size: 1.1rem;
  color: black;
}

.item-details p {
  margin: 5px 0 0;
  color: #666;
}

.item-details p span {
  color: black;
  font-weight: bold;
}

.item-quantity {
  display: flex;
  align-items: center;
}

.item-quantity button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  border: 2px solid var(--secondary);
  padding: 4px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartRightCard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cartRightCard > p {
  color: gray;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: line-through;
}

.cartRightCard > h4 {
  color: black;
  font-size: 17px;
  margin-top: 4px;
}

.item-quantity button:hover {
  background-color: var(--secondary);
  color: white;
}

.item-quantity span {
  margin: 0 10px;
  font-size: 1rem;
  color: black;
}

.cart-footer {
  text-align: center;
}

.checkout-button {
  width: 60%;
  margin: auto;
  padding: 14px;
  background-color: white;
  color: var(--secondary);
  outline: none;
  border: 1px solid var(--secondary);
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.checkout-button:hover {
  background-color: var(--secondary);
  color: white;
}

@keyframes slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 100%);
  }
}

.cart-modal.slide-out {
  animation: slideDown 0.5s ease-in-out;
}

.cartSubtotalContainer {
  margin-top: 10px;
  margin-bottom: 25px;
}

.cartSubtotalContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}

.cartSubtotalContainer > .cartSubtotalTop {
  justify-content: flex-end;
  align-items: center;
  color: gray;
  font-size: 15px;
  margin-top: 10px;
  text-decoration: line-through;
  margin-bottom: 7px;
}
.cartSubtotalContainer > .cartSubtotalBottom > div {
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 16px;
}
.cartSubtotalContainer > .cartSubtotalBottom > div > .subtotalAmt {
  font-size: 18px;
  font-weight: bold;
}
