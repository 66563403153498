.homeContainer {
  width: 100%;
}
.homeContainer > h2 {
  color: white;
  text-align: center;
  font-size: 55px;
  font-weight: 550;
  margin-top: 40px;
}

.homeContainer > p {
  color: rgb(126, 126, 126);
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: lighter;
}

/* .newCollectionBtn {
  width: fit-content;
  padding: 10px 20px;
  background-color: white;
  border-radius: 30px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
} */

.newCollectionBtn > p {
  font-size: 18px;
}

.newCollectionBtn > svg {
  font-size: 28px;
}

.newCollectionBtn {
  width: fit-content;
  padding: 10px 20px;
  background: linear-gradient(
    90deg,
    var(--secondary),
    rgba(255, 255, 255, 0.15),
    var(--secondary)
  );
  background-size: 300% 100%;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  margin: auto;
  margin-top: 25px;
  cursor: pointer;
  border: none;
  transition: background-color 0.7s ease-in-out;
  animation: slideBackground 7s infinite linear;
  border: 1px solid var(--secondary);
}

@keyframes slideBackground {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* ========================slider */
.slider-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.slider-image {
  position: absolute;
  width: 15rem;
  height: 18rem;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  object-fit: cover;
  transition: all 0.3s ease;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  background-color: #4a5568;
  color: #ffffff;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 20;
  border: none;
}

.slider-button:hover {
  background-color: var(--secondary);
}

.slider-button-left {
  left: 1rem;
}

.slider-button-right {
  right: 1rem;
}

.collectionsOptions {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25% 2.5%;
}

.collectionsOptions > .collection {
  width: 24%;
  height: 100%;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.collectionsOptions > .collection > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
  border-radius: 14px;
  transition: opacity 0.3s ease;
}

.collectionsOptions > .collection > p {
  position: relative;
  z-index: 2;
  color: rgb(255, 255, 255);
  font-size: 24px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), -2px -2px 4px rgba(0, 0, 0, 0.8),
    2px -2px 4px rgba(0, 0, 0, 0.8), -2px 2px 4px rgba(0, 0, 0, 0.8);
}

.collectionsOptions > .collection:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.collectionsOptions > .collection:hover > img {
  opacity: 1;
}
