.authentication-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.logo {
  width: 200px; /* Adjust as needed */
  margin-bottom: 20px;
  cursor: pointer;
}

.form-container {
  display: flex;
  width: 1000px;
  height: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  transition: transform 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.left-side {
  background: linear-gradient(15deg, rgb(255, 255, 255), var(--secondary));
  color: rgb(0, 0, 0);
}

.right-side {
  background-color: white;
  color: var(--primary);
}

.welcome-content h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.welcome-content p {
  font-size: 18px;
  margin-bottom: 30px;
}
.forgotPasswordText {
  color: #ff0000;
  margin-bottom: 20px;
  margin-top: -8px;
  cursor: pointer;
  text-align: left;
}

.welcome-content button {
  width: 100%;
  padding: 10px 20px;
  background-color: black;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.welcome-content button:hover {
  background-color: var(--secondary);
  border: none;
}

/* Form Styles */
.form {
  width: 100%;
}

.form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--primary);
}

.input-row {
  display: flex;
  gap: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary);
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  flex: 1;
  margin-bottom: 14px;
}

.withoutpaddinginput {
  padding: 0;
  padding-left: 10px;
}
.withoutpaddinginput input {
  border: 1px solid var(--secondary);
  border-radius: 5px;
}

.input-container .icon {
  margin-right: 10px;
  color: var(--primary);
}

.input-container input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.send-otp-button,
.verify-otp-button {
  padding: 10px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.send-otp-button:hover,
.verify-otp-button:hover {
  background-color: var(--secondary);
}

.form button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.form button[type="submit"]:hover {
  background-color: var(--secondary);
}

/* Slide Animation */
.form-container.login .left-side {
  transform: translateX(0);
}

.form-container.login .right-side {
  transform: translateX(0);
}

.form-container.signup .left-side {
  transform: translateX(100%);
}

.form-container.signup .right-side {
  transform: translateX(-100%);
}
