.profile-container {
  background-color: black;
  color: white;
  padding: 0 2rem;
  border-radius: 10px;
  max-width: 800px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-header {
  text-align: center;
  margin-bottom: 1rem;
}

.profile-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.account-icon {
  font-size: 100px; /* Adjust the size of the icon */
  color: var(--secondary); /* Golden color */
}

.profile-name {
  font-size: 2rem;
  margin: 1rem 0 0.5rem;
  color: var(--secondary);
}

.profile-email,
.profile-phone {
  font-size: 1.2rem;
  color: #e7e7e7;
  margin-bottom: 5px;
}

.profile-section {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1.5rem;
  color: var(--secondary);
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--secondary);
  padding-bottom: 0.5rem;
}

.address-card {
  background-color: #222;
  padding: 1rem;
  border-radius: 5px;
  text-align: center;
}

.address-card p {
  margin: 0.5rem 0;
}

.address-form,
.password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address-form input,
.password-form input {
  padding: 0.75rem;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #222;
  color: white;
}

.action-button {
  background-color: var(--secondary);
  color: black;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #d4af37;
}

.edit-address,
.add-address,
.cancel-address,
.cancel-password {
  background-color: transparent;
  border: 2px solid var(--secondary);
  color: var(--secondary);
}

.edit-address:hover,
.add-address:hover,
.cancel-address:hover,
.cancel-password:hover {
  background-color: var(--secondary);
  color: black;
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}
