.checkoutFieldsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  margin-bottom: 14px;
  margin-left: 10px;
}

.innerForm {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.innerForm svg {
  color: var(--secondary);
  font-size: 28px;
  cursor: pointer;
  margin-right: 10px;
}

.innerForm svg:hover {
  color: black;
}

.addressFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.innerForm > button {
  padding: 10px 20px;
  background-color: var(--secondary);
  color: white;
  border: 1px solid var(--secondary);
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
}

.innerForm > button:hover {
  color: var(--secondary);
  background-color: black;
  border: 1px solid black;
}

.form-group label {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.form-group .icon {
  margin-right: 10px;
  color: var(--secondary);
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.payment-method {
  margin-top: 20px;
}

.payment-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-left: 25px;
}

.payment-options label {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-options input {
  flex: 1;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.cart-footer {
  text-align: center;
}

.checkout-button {
  width: 60%;
  margin: auto;
  padding: 14px;
  background-color: white;
  color: var(--secondary);
  outline: none;
  border: 1px solid var(--secondary);
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.checkout-button:hover {
  background-color: var(--secondary);
  color: white;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 100%);
  }
}

.cart-modal.slide-out {
  animation: slideDown 0.5s ease-in-out;
}
